<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Bangsal</b> Yang Ditempati</div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/beds/add-allotment')"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between">
              <div class="col-md-7">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #prepend>
                      <b-button
                        squared
                        @click="resetFilterPeriode"
                        variant="danger"
                        >Reset</b-button
                      >
                      <b-button
                        squared
                        @click="filterByPeriode"
                        variant="success"
                        >Cari</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Masuk"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">dan/atau</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Keluar"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetFilterPeriode"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="filterByPeriode"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Masuk"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">dan/atau</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Keluar"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.patient_name"
                    placeholder="Cari Nama Pasien"
                    @keyup="filterByPatientName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <b-table striped hover class="mt-3" :items="items" :fields="fields" responsive>
              <template #cell(allotedTime)="data">
                {{ handleDate(data.item.date_in) }}
              </template>
              <template #cell(dischargeTime)="data">
                {{ handleDate(data.item.date_out) }}
              </template>
              <template #cell(actions)="data">
                <div v-if="data.item.is_used == 1">
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger mb-1"
                    v-if="usedBtn == true"
                    @click="handleEdit(data.item.id)"
                    >Kosongkan</b-button
                  >
                </div>
                <div v-if="data.item.is_used == 0">
                  <b-button
                    size="sm"
                    class="mr-1 btn-primary mb-1"
                    v-b-tooltip.hover
                    title="Selesai"
                    ><i class="fas fa-check-square"></i
                  ></b-button>
                </div>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Ubah"
                  v-if="manipulateBtn == true"
                  @click="
                    $router.push({
                      path: '/beds/edit-allotment/' + data.item.id,
                    })
                  "
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="manipulateBtn == true"
                  @click="handleDelete(data.item.id)"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "bed_id",
          label: "Kode Bangsal",
          sortable: true,
        },
        {
          key: "patient_nik",
          label: "NIK Pasien",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "bed_name",
          label: "Bangsal",
          sortable: true,
        },
        {
          key: "allotedTime",
          label: "Tanggal Masuk",
          sortable: true,
        },
        {
          key: "dischargeTime",
          label: "Tanggal Keluar",
          sortable: true,
        },
        { key: "actions", label: "Aksi", tdClass: "width:30%" },
      ],
      items: [],
      manipulateBtn: false,
      usedBtn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "used-beds",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    handleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    filterByPatientName() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.pagination();
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      this.pagination();
    },

    filterByPeriode() {
      this.pagination();
    },

    async handleEdit(id) {
      let data = await module.get("used-beds/" + id);
      data["_method"] = "put";
      data["is_used"] = 0;
      let response = await module.submit(data, "used-beds-delete/" + id);
      // Check Response
      if (response.state != "error") {
        // Success
        this.$root.$emit("UpdateNewAvailableBed");
        Swal.fire("Berhasil", "Berhasil Mengubah Status", "success");
        this.pagination();
      }
    },

    async handleDelete(id) {
      let result = await module.delete("used-beds/" + id);
      if (result) {
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "1302") {
          this.manipulateBtn = true;
        }
        if (access_right[a] == "1303") {
          this.usedBtn = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal" },
      { title: "Pemakaian" },
    ]);
    this.pagination();
    this.setActiveMenu();
  },
};
</script>

<style>
</style>